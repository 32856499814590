var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "小程序测肤",
          "left-arrow": "",
          fixed: "",
          placeholder: "",
        },
        on: { "click-left": _vm.close },
      }),
      _vm.tableData.length > 0
        ? _c(
            "div",
            [
              _vm._l(_vm.tableData, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "con skin-testing-body-box" },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { title: "", column: 2, border: "" } },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "id:" } },
                          [_vm._v(_vm._s(item.oid))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "测肤Id:" } },
                          [_vm._v(_vm._s(item.detectResultId))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "测肤时间:" } },
                          [_vm._v(_vm._s(item.detectTime))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-descriptions",
                      { attrs: { title: "", column: 2, border: "" } },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "肤色:" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("getStateName")(
                                  item.color.value,
                                  _vm.colorType
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "肤龄:" } },
                          [_vm._v(_vm._s(Number(item.age.value)))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "肤质:" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("getStateName")(
                                  item.skinType.value,
                                  _vm.skinList
                                )
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-table",
                      {
                        staticClass: "tables",
                        staticStyle: { width: "100%" },
                        attrs: { size: "mini", data: _vm.tableInfo },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "degree",
                            label: "",
                            width: "50",
                            align: "center",
                            fixed: "",
                          },
                        }),
                        _vm._l(item.classifyRootList, function (item, index) {
                          return _c(
                            "el-table-column",
                            {
                              key: index,
                              attrs: {
                                prop: "date",
                                label: item.classifyName,
                                "min-width": "80",
                                align: "center",
                              },
                            },
                            _vm._l(
                              item.leafClassifyList,
                              function (items, indexs) {
                                return _c("el-table-column", {
                                  key: indexs,
                                  attrs: {
                                    prop: "province",
                                    label: items.classifyName,
                                    "min-width": "80",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.$index == 0
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      class: [
                                                        "close",
                                                        items.score.color
                                                          ? "on"
                                                          : "",
                                                      ],
                                                      style: {
                                                        background: items.score
                                                          .color
                                                          ? items.score.color
                                                          : "#f7f7f7",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          Number(
                                                            items.score.value
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                            scope.$index == 1
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      class: [
                                                        "close",
                                                        items.score.color
                                                          ? "on"
                                                          : "",
                                                      ],
                                                      style: {
                                                        background: items.score
                                                          .color
                                                          ? items.score.color
                                                          : "#f7f7f7",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          Number(
                                                            items.score.value
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }
                            ),
                            1
                          )
                        }),
                      ],
                      2
                    ),
                    _c(
                      "el-collapse",
                      {
                        model: {
                          value: _vm.activeNames,
                          callback: function ($$v) {
                            _vm.activeNames = $$v
                          },
                          expression: "activeNames",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          { attrs: { title: "数据解读", name: "1" } },
                          [
                            _c(
                              "el-table",
                              {
                                staticClass: "tables",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  size: "mini",
                                  border: "",
                                  data: item.explainList,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "指标名",
                                    prop: "fileNameDesc",
                                    "min-width": "80",
                                    fixed: "",
                                    align: "center",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "值/范围",
                                    prop: "configValue",
                                    "min-width": "80",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.configValue ==
                                                  "大于：0, 小于：1000"
                                                  ? "0-100"
                                                  : scope.row.configValue
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "现值",
                                    prop: "matchValue",
                                    "min-width": "80",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$util.fmtToFix(
                                                  scope.row.matchValue,
                                                  1
                                                )
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: "异常",
                                      prop: "color",
                                      "min-width": "80",
                                      align: "center",
                                    },
                                  },
                                  [[_vm._v("是")]],
                                  2
                                ),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "解读内容",
                                    prop: "explainMsg",
                                    "min-width": "80",
                                    align: "center",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "w-1",
                        staticStyle: { "text-align": "right" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn",
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.goDetail(item)
                              },
                            },
                          },
                          [_vm._v("查看详情")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c("el-pagination", {
                staticStyle: { "text-align": "right" },
                attrs: {
                  "pager-count": 5,
                  background: "",
                  layout: "prev, pager, next",
                  total: _vm.totalSize,
                },
                on: { "current-change": _vm.changepage },
              }),
            ],
            2
          )
        : _c("el-empty"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }