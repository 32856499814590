<template>
  <div class='container'>
    <van-nav-bar title="小程序测肤" left-arrow fixed placeholder @click-left="close" />
    <div v-if="tableData.length>0">
      <div class="con skin-testing-body-box" v-for="(item,index) in tableData" :key="index">
        <el-descriptions title="" :column="2" border>
          <el-descriptions-item label="id:">{{item.oid}}</el-descriptions-item>
          <el-descriptions-item label="测肤Id:">{{item.detectResultId}}</el-descriptions-item>
          <el-descriptions-item label="测肤时间:">{{item.detectTime}}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="2" border>
          <el-descriptions-item label="肤色:">{{item.color.value|getStateName(colorType)}}</el-descriptions-item>
          <el-descriptions-item label="肤龄:">{{Number(item.age.value)}}</el-descriptions-item>
          <el-descriptions-item label="肤质:">{{item.skinType.value|getStateName(skinList)}}</el-descriptions-item>
        </el-descriptions>
        <el-table size="mini" class="tables" :data="tableInfo" style="width: 100%;">
          <el-table-column prop="degree" label="" width="50" align="center" fixed>
          </el-table-column>
          <el-table-column prop="date" :label="item.classifyName" min-width="80" align="center"
            v-for="(item,index) in item.classifyRootList" :key="index">
            <el-table-column prop="province" :label="items.classifyName" min-width="80"
              v-for="(items,indexs) in item.leafClassifyList" :key="indexs" align="center">
              <template slot-scope="scope">
                <div v-if="scope.$index==0">
                  <div :class="['close',items.score.color?'on':'']"
                    :style="{background: items.score.color?items.score.color:'#f7f7f7'}">{{Number(items.score.value)}}
                  </div>
                </div>
                <div v-if="scope.$index==1">
                  <div :class="['close',items.score.color?'on':'']"
                    :style="{background: items.score.color?items.score.color:'#f7f7f7'}">{{Number(items.score.value)}}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <el-collapse v-model="activeNames">
          <el-collapse-item title="数据解读" name="1">
            <el-table size="mini" class="tables" border :data="item.explainList" style="width: 100%;">
              <el-table-column label="指标名" prop="fileNameDesc" min-width="80" fixed align="center">
              </el-table-column>
              <el-table-column label="值/范围" prop="configValue" min-width="80" align="center">
                <template slot-scope="scope">{{scope.row.configValue=='大于：0, 小于：1000'?'0-100':scope.row.configValue}}</template>
              </el-table-column>
              <el-table-column label="现值" prop="matchValue" min-width="80" align="center">
                <template slot-scope="scope">{{$util.fmtToFix(scope.row.matchValue,1)}}</template>
              </el-table-column>
              <el-table-column label="异常" prop="color" min-width="80" align="center">
                <template>是</template>
              </el-table-column>
              <el-table-column label="解读内容" prop="explainMsg" min-width="80" align="center">
              </el-table-column>
            </el-table>
          </el-collapse-item>
        </el-collapse>
        <div class="w-1" style="text-align: right;">
          <el-button class="btn" size="small" type="primary" @click="goDetail(item)">查看详情</el-button>
        </div>

      </div>
      <el-pagination style="text-align: right;" :pager-count="5" background layout="prev, pager, next" :total="totalSize"
        @current-change="changepage">
      </el-pagination>
    </div>
    <el-empty v-else></el-empty>
  </div>
</template>

<script>
  // 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  // 例如：import 《组件名称》 from '《组件路径》'
  import homeManager from "@/request/homeManager.js";
  export default {
    // import引入的组件需要注入到对象中才能使用
    components: {},
    data () {
      // 这里存放数据
      return {
        requst: {
          module: '',
          url: '',
        },
        query: {},
        tableData: [],
        tableInfo: [{
          degree: '程度',
          list: [],
          list_TWO: []
        }, {
          degree: '面积',
          list: [],
          list_TWO: []
        }],
        activeNames: '0',
        dataInfo: [],
        pageSize: 10,
        currentPage: 1,
        totalSize: 0,
        skinList: null,
        colorType: null,
      }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created () {
      this.pageOrder()
      this.getBySourceType()
      this.getBySourceTypes()
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted () { },
    // 方法集合
    methods: {
      async pageOrder () {
        try {
          let { code, data } = await homeManager.detectRecord({ pageSize: this.pageSize, currentPage: this.currentPage, custId: this.$store.getters.custId })
          if (code == 200) {
            this.tableData = data.dataList
            this.totalSize = data.totalSize
          }
        } catch (error) {
          //
        }
      },
      //肤色
      async getBySourceType () {
        try {
          let { code, data } = await homeManager.getDictByCode({ sysCode: 'customer', sysBizCode: 'customer', dictCode: 'customerAiDetectSkinColor' })
          if (code == 200) {
            this.colorType = data
          }
        } catch (error) {
          //
        }
      },
      //肤质
      async getBySourceTypes () {
        try {
          let { code, data } = await homeManager.getDictByCode({ sysCode: 'customer', sysBizCode: 'customer', dictCode: 'customerAiDetectSkinType' })
          if (code == 200) {
            this.skinList = data
          }
        } catch (error) {
          //
        }
      },
      //改变页码
      changepage (val) {
        this.currentPage = val
        this.pageOrder()
      },
      close () {
        this.$router.go(-1);
      },
      goDetail (item) {
        this.$router.push({
          path: '/report',
          query: { detectResultId: item.detectResultId }
        })
      },
    },
    filters: {
      getStateName (value, arr) {
        if (arr) {
          var array = arr.filter((obj) => {
            return value === obj.dictItemCode;
          });
          if (array.length !== 0) {
            return array[0].dictItemName;
          } else {
            return "-";
          }
        } else {
          return "-";
        }
      },
    },
    // 生命周期 - 创建之前
    beforeCreate () { },
    // 生命周期 - 挂载之前
    beforeMount () { },
    // 生命周期 - 更新之前
    beforeUpdate () { },
    // 生命周期 - 更新之后
    updated () { },
    // 生命周期 - 销毁之前
    beforeDestroy () { },
    // 生命周期 - 销毁完成
    destroyed () { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated () { },
  }
</script>

<style scoped lang="scss">
  .container {
    min-height: 100vh;
    background: #f7f7f7;
    padding: 20px;
    box-sizing: border-box;
  }

  .header {
    margin: 20px 0;
  }

  .header span {
    font-size: 30px;
    font-weight: 600;
  }

  .btn {
    font-size: 30px;
    margin-top: 20px;
  }

  .tables {
    font-size: 20px;
  }

  .con {
    padding: 20px;
    margin-bottom: 20px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .el-descriptions {
    margin-bottom: 20px;
  }

  .close{
    padding: 5px 0;
    margin-bottom: 5px;
    border-radius: 5px;
  }
  .on{
    color: #fff;
  }
  ::v-deep .van-nav-bar--fixed{
    z-index: 999;
  }
  ::v-deep .el-table .el-table__cell {
    padding: 5px 0;
  }
  ::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell{
    padding: 10px;
  }


  .skin-testing-body-box {
  padding: 20px 20px;
  box-sizing: border-box;
  ::v-deep .el-descriptions {
    .el-descriptions-item__label {
      width: 160px;
    }
  }
}
</style>